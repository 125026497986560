import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import dateFormat from 'dateformat';

import Seo from "../components/Seo"
import MainContent from "../components/MainContent"
import RichText from "../components/RichText"
import { AccessTime } from "../images/svg-icons"
import Header from '../components/Header';
import Footer from '../components/Footer';


const BlogPost = ({ data, location, pageContext }) => {
  const { blogIndex, itemsPerPage } = pageContext;

  if (
    !data.allContentfulBlog ||
    !data.allContentfulBlog.edges ||
    data.allContentfulBlog.edges.length === 0
  ) {
    return null;
  }

  const blogPost = data.allContentfulBlog.edges[0].node;

  const blogPage = Math.floor(blogIndex / itemsPerPage) + 1;
  const hasImage = blogPost.featuredImage && blogPost.featuredImage.fluid;
  const blogUrl = `/blog/${blogPost.slug}`;

  return (
    <>
      <Header />
      <MainContent pathname={location.pathname}>
        <Seo
          title={blogPost.metaTitle}
          description={blogPost.metaDescription}
          canonical={location.pathname.substr(1)}
        />
        <p className="breadcrumbs">
          <Link to="/blog">Blog/News</Link>
          {blogPage !== 1 && (
            <>
              <span>&gt;</span>
              <Link to={`/blog/${blogPage}`}>Page {blogPage}</Link>
            </>
          )}
          <span>&gt;</span>
          <Link to={blogUrl}>{blogPost.title}</Link>
        </p>
        <div className="blogPost">
          <h1>{blogPost.title}</h1>
          <p className="time"><AccessTime />{dateFormat(blogPost.createdAt, 'dddd, mmmm dS, yyyy, h:MM:ss TT')}</p>
          {
            hasImage &&
            <Img fluid={blogPost.featuredImage.fluid} alt={blogPost.featuredImage.title} />
          }
          <div className="markdown">
            <RichText data={blogPost.content} />
          </div>
        </div>
      </MainContent>
      <Footer />
    </>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query($slug: String!, $contentImgMaxWidth: Int!, $imgQuality: Int!, $traceSvgColor: String!) {
    allContentfulBlog(filter: {slug: {eq: $slug}}) {
      edges {
        node {
          createdAt
          title
          slug
          content {
            raw
            references {
              ... on ContentfulAsset {
                cid: contentful_id
                fixed(
                  width: $contentImgMaxWidth,
                  quality: $imgQuality,
                  background: $traceSvgColor
                ) {
                  src
                }
                alt: title
                href: description
              }
            }
          }
          featuredImage {
            title
            fluid(
              maxWidth: 912,
              quality: $imgQuality,
              background: $traceSvgColor
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          metaDescription
          metaTitle
        }
      }
    }
  }
`;
